export const routes = {
  confirmAddress: '/confirm_address',
  confirmAddressExternal: '/confirm_address.php',
  confirmPrintLabel: '/confirm_print_label',
  confirmSchedulePickup: '/confirm_schedule_pickup',
  generateLabel: '/r/:job_id',
  index: '/',
  pickups: '/pickups',
  printLabels: '/print_labels',
  reprintLabel: '/reprint_label',
  schedulePickup: '/schedule_pickup',
  selectTrackingNumbers: '/tracking_numbers',
}
