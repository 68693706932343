import * as React from 'react'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'

import { Button } from '../Button'

export type Props = {
  children: React.ReactNode
  loading?: boolean
  onCancel: () => void
  onConfirm: () => void
  show: boolean
  title?: string
}

export const ChickenMessage = ({ children, loading, onCancel, onConfirm, show, title }: Props) => (
  <Modal onHide={onCancel} show={show}>
    <Modal.Header closeButton>{title && <Modal.Title>{title}</Modal.Title>}</Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer>
      <Row className="justify-content-end w-100">
        <Col xs={4}>
          <Button className="w-100" onClick={onCancel} variant="text">
            Cancel
          </Button>
        </Col>
        <Col xs={4}>
          <Button className="w-100" loading={loading} onClick={onConfirm} variant="primary">
            Confirm
          </Button>
        </Col>
      </Row>
    </Modal.Footer>
  </Modal>
)
