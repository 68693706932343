import * as React from 'react'
import classNames from 'classnames'
import { Field, FieldProps } from 'formik'
import Form from 'react-bootstrap/Form'

import './TextField.scss'

export type Props = {
  className?: string
  disabled?: boolean
  error?: string
  hideLabel?: boolean
  horizontal?: boolean
  label?: string
  maxLength?: number
  name: string
  onBlur?: () => void
  placeholder?: string
  showValidation?: boolean
  type?: 'email' | 'tel' | 'text'
}

export const TextField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      disabled = false,
      error,
      hideLabel = false,
      horizontal,
      label,
      maxLength,
      name,
      onBlur,
      placeholder,
      showValidation = true,
      type = 'text',
    },
    ref,
  ) => (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <Form.Group
          className={classNames({ 'd-flex align-items-center': horizontal }, className)}
          controlId={name}
        >
          {label && (
            <Form.Label
              className={classNames({ 'me-2 mb-0': horizontal })}
              visuallyHidden={hideLabel}
            >
              {label}
            </Form.Label>
          )}
          <Form.Control
            {...field}
            aria-label={label}
            disabled={disabled}
            isInvalid={showValidation && (Boolean(error) || (meta.touched && Boolean(meta.error)))}
            maxLength={maxLength}
            onBlur={e => {
              field.onBlur(e)

              if (onBlur) {
                onBlur()
              }
            }}
            placeholder={placeholder}
            ref={ref}
            type={type}
          />
          {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
        </Form.Group>
      )}
    </Field>
  ),
)

TextField.displayName = 'TextField'
