import * as React from 'react'
import ReactDOM from 'react-dom/client'

import { App } from 'App'
import 'styles/index.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// Set up API mocking for dev environment
if (import.meta.env.NODE_ENV === 'development' && import.meta.env.VITE_USE_MOCK_API === '1') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/no-unsafe-assignment
  const { worker } = require('mocks/browser')
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
  void worker.start()
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
