import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import { parsePhoneNumber } from 'libphonenumber-js'
import Row from 'react-bootstrap/Row'

import { Alert } from 'components/Alert'
import { Button } from 'components/Button'
import { earliestPickupTimeOptions, latestPickupTimeOptions } from 'utils/pickupTimes'
import { RadioButton } from 'components/RadioButton'
import { routes } from 'routes'
import { TextField } from 'components/TextField'
import { Select } from 'components/Select'

import { SchedulePickupSchema } from './schema'
import { AddressOverview } from './components/AddressOverview'

export type FormValues = {
  address1: string
  address2: string
  addressType: 'default' | 'different'
  city: string
  company: string
  country: string
  earliestTime: string
  email: string
  firstName: string
  lastName: string
  latestTime: string
  phone: string
  pickupDate: string
  pointOfPickup: string
  postalCode: string
  specialInstructions?: string
  state: string
}

export type Props = {
  error: string
  initialValues: FormValues
  onSubmit: (formValues: FormValues, setSubmitting: (submitting: boolean) => void) => void
  pickupAvailability: { label: string; value: string }[]
  states: { label: string; value: string }[]
  trackingNumbers: string[]
}

export const SchedulePickupConfirmationForm = ({
  error,
  initialValues,
  onSubmit,
  pickupAvailability,
  states,
  trackingNumbers,
}: Props) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers.setSubmitting)}
    validateOnMount
    validationSchema={SchedulePickupSchema}
  >
    {({ errors, handleSubmit, isSubmitting, setFieldValue, submitCount, touched, values }) => (
      <Form onSubmit={handleSubmit}>
        <Row className="mt-2">
          <Col>
            <Alert>{error}</Alert>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col className="d-flex flex-wrap pt-3 justify-content-center">
            <div className="d-inline-block h4">
              <strong>Tracking #</strong> {trackingNumbers.join(', ')}
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col md={{ offset: 2, span: 4 }} sm={{ offset: 0, span: 12 }} xxl={4}>
            <RadioButton label="Use Address On Label" name="addressType" value="default" />
            {values.addressType === 'default' && <AddressOverview address={initialValues} />}

            <RadioButton
              className="my-3"
              label="Use Different Address"
              name="addressType"
              value="different"
            />
          </Col>
        </Row>

        {values.addressType === 'different' && (
          <>
            <Row className="justify-content-center mb-2">
              <Col lg={4} sm={6} xxl={4}>
                <TextField
                  error={touched.firstName ? errors.firstName : ''}
                  label="Contact Name:"
                  name="firstName"
                  placeholder="First name"
                />
              </Col>
              <Col className="mt-1 mt-sm-3 pt-sm-3" lg={4} sm={6} xxl={4}>
                <TextField
                  error={touched.lastName ? errors.lastName : ''}
                  hideLabel
                  label="Contact last name"
                  name="lastName"
                  placeholder="Last name"
                />
              </Col>
            </Row>

            <Row className="justify-content-center mb-2">
              <Col lg={8} xxl={8}>
                <TextField
                  error={touched.company ? errors.company : ''}
                  label="Company Name:"
                  name="company"
                />
              </Col>
            </Row>

            <Row className="justify-content-center mb-1">
              <Col lg={8} xxl={8}>
                <TextField
                  error={touched.address1 ? errors.address1 : ''}
                  label="Address:"
                  name="address1"
                  placeholder="Street address"
                />
              </Col>
            </Row>
            <Row className="justify-content-center mb-2">
              <Col lg={8} xxl={8}>
                <TextField
                  hideLabel
                  label="Address line 2"
                  name="address2"
                  placeholder="Apt, suite, etc."
                />
              </Col>
            </Row>

            <Row className="justify-content-center mb-2">
              <Col className="mb-1 mb-lg-0" lg={4} xxl={4}>
                <TextField error={touched.city ? errors.city : ''} label="City:" name="city" />
              </Col>
              <Col className="mb-1 mb-lg-0" lg={2} xxl={2}>
                <Select label="State:" name="state" options={states} />
              </Col>
              <Col className="mb-1 mb-lg-0" lg={2} xxl={2}>
                <TextField
                  error={touched.postalCode ? errors.postalCode : ''}
                  label="Zip:"
                  name="postalCode"
                />
              </Col>
            </Row>

            <Row className="justify-content-center mb-2">
              <Col lg={8} xxl={8}>
                <TextField disabled label="Country:" name="country" />
              </Col>
            </Row>

            <Row className="justify-content-center mb-2">
              <Col lg={8} xxl={8}>
                <TextField
                  error={touched.phone ? errors.phone : ''}
                  label="Phone Number:"
                  name="phone"
                  onBlur={() =>
                    setFieldValue('phone', parsePhoneNumber(values.phone, 'US').formatNational())
                  }
                  type="tel"
                />
              </Col>
            </Row>

            <Row className="justify-content-center mb-4">
              <Col lg={8} xxl={8}>
                <TextField
                  error={touched.email ? errors.email : ''}
                  label="Email:"
                  name="email"
                  type="email"
                />
              </Col>
            </Row>
            <Row className="justify-content-center mb-3">
              <Col>
                <p className="fw-bold text-center">
                  Please enter any address corrections above, then click Next.
                </p>
              </Col>
            </Row>
          </>
        )}
        <hr />

        <Row className="justify-content-center mb-1">
          <Col lg={8} xxl={8}>
            <TextField
              label="Point of Pickup:"
              maxLength={11}
              name="pointOfPickup"
              placeholder="Front Door"
            />
          </Col>
        </Row>

        <Row className="justify-content-center mb-1">
          <Col lg={8} xxl={8}>
            <TextField label="Special Instructions:" maxLength={50} name="specialInstructions" />
          </Col>
        </Row>

        <Row className="mb-1">
          <Col className="mb-1 mb-lg-0" lg={{ span: 3, offset: 2 }} xxl={3}>
            <Select label="Date of Pickup" name="pickupDate" options={pickupAvailability} />
          </Col>
        </Row>

        <Row className="mb-1">
          <Col className="mb-1 mb-lg-0" lg={{ span: 3, offset: 2 }} xxl={3}>
            <Select
              label="Earliest Pickup Time:"
              name="earliestTime"
              options={earliestPickupTimeOptions}
            />
          </Col>
        </Row>

        <Row className="mb-1">
          <Col className="mb-1 mb-lg-0" lg={{ span: 3, offset: 2 }} xxl={3}>
            <Select
              label="Latest Pickup Time:"
              name="latestTime"
              options={latestPickupTimeOptions}
            />
          </Col>
        </Row>

        {submitCount > 0 && Object.keys(errors).length > 0 && (
          <Row className="mt-4">
            <Col>
              <Alert type="danger">
                Please enter valid values into the highlighted fields above.
              </Alert>
            </Col>
          </Row>
        )}

        <Row className="justify-content-center mt-4 mb-4">
          <Col lg={3} xs={12}>
            <Link className="btn btn-secondary py-2 w-100" to={routes.index}>
              Go Back
            </Link>
          </Col>
          <Col className="mb-2" lg={3} xs={12}>
            <Button className="w-100" loading={isSubmitting} type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
)
