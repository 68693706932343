// API types

export type ApiRequestBody = { action: string }

export type CreateLabelsRequestParams = {
  arsNumber: string
  labels: Array<Label>
} & (
  | { shipType: ShipType.daily | ShipType.dropoff }
  | { earliestTime: string; latestTime: string; pickupDate: string; shipType: ShipType.schedule }
)

export type EmailLabelRequestParams = GetLabelRequestParams & {
  email: string
}

export type EmailLabelsRequestParams = CreateLabelsRequestParams & {
  email: string
}

export type GetLabelRequestParams = {
  arsNumber: string
  labelNumbers: Array<number>
}

export type GetLabelResponse = {
  pdfpath: string
}

export type GetOrderRequestParams = {
  dispatchId?: string
  orderNumber?: string
}

export type GetOrderResponse = {
  ars_number: string
  job: GetOrderResponseJob
  label_count: number
  label_details: Array<GetOrderResponseLabelDetail>
  settings: GetOrderResponseSettings
}

export type GetOrderResponseJob = {
  address1: string
  address2: string
  city: string
  company: string
  country: string
  job_destination_id: string
  job_id: string
  lname: string
  location_name: string
  name: string
  phone: string
  project: string
  service_tags: string
  state: string
  status_code: string
  user_email: string
  zip: string
}

export type GetOrderResponseLabelDetail = {
  asset_tag: string
  asset_type: string
  asset_weight: string
  ship_status: string
  tracking_number: string
}

export type GetOrderResponseSettings = {
  asset_types: Array<GetOrderResponseSettingsAssetType>
  pickupAvailability: {
    dates: Array<GetOrderResponseSettingsPickupAvailabilityDate>
  }
  states: Array<GetOrderResponseSettingsState>
}

export type GetOrderResponseSettingsAssetType = {
  name: string
  typeId: number
}

export type GetOrderResponseSettingsPickupAvailabilityDate = {
  date: string
  display_date: string
}

export type GetOrderResponseSettingsState = {
  name: string
  state: string
}

export type SchedulePickupRequestParams = {
  address: {
    address1: string
    address2: string
    city: string
    company: string
    country: string
    email: string
    lname: string
    name: string
    phone: string
    postal_code: string
    state_province: string
  }
  ars_num: string
  pickup: {
    earliest_time: string
    latest_time: string
    pickup_date: string
    point_of_pickup: string
    ship_type: ShipType.schedule
  }
  special_instructions: string
  tracking_numbers: Array<string>
}

export type UpdateOrderResponse = {
  address: {
    address1: string
    address2: string
    address_id: number
    city: string
    country: string
    postal_code: string
    residence: 0 | 1
    state_province: string
  }
  allow_override: 0 | 1
  valid_address: boolean
}

// Order types

export type Order = {
  arsNumber: string
  jobId: string
  labels: Array<Label>
  location: Location
  settings: OrderSettings
  status: string
  trackingNumbers?: Array<string>
}

export type Label = {
  assetTypeId: number
  labelNumber: number
  selected?: boolean
  serialNumber: string
  status: string
  trackingNumber: string
}

export type Location = {
  address: Address
  company: string
  contact: Contact
}

export type Address = {
  address1: string
  address2: string
  addressId?: number
  city: string
  country: string
  postalCode: string
  state: string
}

export type Contact = {
  email: string
  firstName: string
  lastName: string
  phone: string
}

export type OrderSettings = {
  assetTypes: Array<AssetType>
  pickupAvailability: Array<PickupAvailabilityDate>
  states: Array<State>
}

export type AssetType = {
  name: string
  typeId: number
}

export type PickupAvailabilityDate = {
  date: string
  displayDate: string
}

export type State = {
  name: string
  state: string
}

export enum ShipType {
  daily = 'daily',
  dropoff = 'dropoff',
  none = '',
  schedule = 'schedule',
}
