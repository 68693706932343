import * as Yup from 'yup'
import { isValidPhoneNumber } from 'libphonenumber-js'

const requiredMessage = 'Required'

const buildRequiredForDifferentAddress = () => ({
  is: (val: string) => val === 'different',
  then: (val: Yup.StringSchema<string | undefined, Yup.AnyObject, undefined, ''>) =>
    val.required(requiredMessage),
})

export const SchedulePickupSchema = Yup.object().shape({
  addressType: Yup.string(),
  firstName: Yup.string().when('addressType', buildRequiredForDifferentAddress()),
  lastName: Yup.string().when('addressType', buildRequiredForDifferentAddress()),
  company: Yup.string().when('addressType', buildRequiredForDifferentAddress()),
  address1: Yup.string().when('addressType', buildRequiredForDifferentAddress()),
  address2: Yup.string(),
  city: Yup.string().when('addressType', buildRequiredForDifferentAddress()),
  state: Yup.string().when('addressType', buildRequiredForDifferentAddress()),
  postalCode: Yup.string().when('addressType', buildRequiredForDifferentAddress()),
  country: Yup.string().when('addressType', buildRequiredForDifferentAddress()),
  phone: Yup.string().when('addressType', {
    is: (val: string) => val === 'different',
    then: val =>
      val
        .required(requiredMessage)
        .test('phone-number', 'Please enter a valid phone number.', value =>
          isValidPhoneNumber(value ?? '', 'US'),
        ),
  }),
  email: Yup.string().when('addressType', {
    is: (val: string) => val === 'different',
    then: val => val.email().required(requiredMessage),
  }),
  pointOfPickup: Yup.string(),
  specialInstructions: Yup.string(),
  pickupDate: Yup.string().required(),
  earliestTime: Yup.string().required(),
  latestTime: Yup.string()
    .default('1700')
    .test('end-time', 'End time cannot be equal to or before start time', (value, context) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { earliestTime }: { earliestTime: string } = context.parent
      return value > earliestTime
    }),
})
