import { Outlet } from 'react-router-dom'

import { Footer } from 'components/Footer'
import { Navbar } from 'components/Navbar'

export const Layout = () => (
  <div className="d-flex flex-column vh-100">
    <Navbar />

    <div className="flex-grow-1">
      <Outlet />
    </div>

    <Footer />
  </div>
)
