import * as React from 'react'
import BootstrapAlert from 'react-bootstrap/Alert'

type Props = {
  children?: React.ReactNode
  className?: string
  type?: 'danger' | 'success'
}

export const Alert = ({ children, className, type = 'danger' }: Props) =>
  children ? (
    <BootstrapAlert className={className} role="alert" variant={type}>
      {children}
    </BootstrapAlert>
  ) : null
