import * as React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { Link, Outlet, useLocation } from 'react-router-dom'
import Row from 'react-bootstrap/Row'

import { Card } from 'components/Card'
import { routes } from 'routes'

import './FormLayout.scss'

export const FormLayout = () => {
  // Scroll to the top of the page on route transitions
  const location = useLocation()
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div className="FormLayout">
      <Container>
        <Row className="justify-content-center">
          <Col className="p-4" lg={10} xs={12}>
            <Card>
              <Outlet />

              <Row>
                <Col className="text-center">
                  <Link to={routes.index}>Return to Dell Return Center Home Page</Link>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
