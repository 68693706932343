import classNames from 'classnames'
import { Field, FieldProps } from 'formik'
import Form from 'react-bootstrap/Form'

import './Select.scss'

export type SelectOption = {
  label: string
  value: string | number
}

export type Props = {
  className?: string
  horizontal?: boolean
  label?: string
  name: string
  options: SelectOption[]
  showErrorMessage?: boolean
  showValidation?: boolean
}

export const Select = ({
  className,
  horizontal,
  label,
  name,
  options,
  showErrorMessage = true,
  showValidation = true,
}: Props) => (
  <Field name={name}>
    {({ field, meta }: FieldProps) => (
      <Form.Group
        className={classNames(className, { 'd-flex align-items-center': horizontal })}
        controlId={name}
      >
        {label && (
          <Form.Label className={classNames({ 'me-2 mb-0': horizontal })}>{label}</Form.Label>
        )}
        <Form.Select {...field} isInvalid={showValidation && meta.touched && Boolean(meta.error)}>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Select>
        {showErrorMessage && (
          <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
        )}
      </Form.Group>
    )}
  </Field>
)
