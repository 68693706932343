import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import InkAndTonerRecyclingIcon from 'images/ink_toner_recycling.png'
import OnePakLogo from 'images/onepak_logo.png'

import './Footer.scss'

const currentYear = new Date().getFullYear()

export const Footer = () => (
  <footer className="Footer px-3 py-5 text-white">
    <Row>
      <Col className="d-flex flex-column align-items-center justify-content-center" lg={3} xs={12}>
        <div>
          <p className="mb-3">Powered by</p>
          <img alt="OnePak logo" src={OnePakLogo} />
        </div>
      </Col>
      <Col
        className="Footer__license border-lg-end p-3 pe-lg-5 text-center text-lg-start"
        lg={4}
        xs={12}
      >
        <p>
          This site is protected by copyright and trademark laws under U.S. and international law.
        </p>
        <p>All Dell logos and branding used by permission and are property of Dell, Inc.</p>
        <p>Copyright © {currentYear} by OnePak, Inc. All rights reserved.</p>
      </Col>
      <Col className="d-flex align-items-center justify-content-center" lg={5} xs={12}>
        <a
          aria-label="Ink & toner recycling link"
          className="Footer__inkAndTonerRecyclingLink"
          href="https://corporate.delltechnologies.com/en-us/social-impact/advancing-sustainability/how-to-recycle.htm/"
        >
          <div>
            <p>Ink & Toner Recycling</p>
            <img alt="Ink & toner recycling icon" src={InkAndTonerRecyclingIcon} />
          </div>
        </a>
      </Col>
    </Row>
  </footer>
)
