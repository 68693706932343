import BootstrapNavbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'

import DellTechnologiesLogo from 'images/dell_logo.png'

import './Navbar.scss'

export const Navbar = () => (
  <BootstrapNavbar bg="dell-gray" expand="sm" sticky="top">
    <Container fluid>
      <BootstrapNavbar.Brand>
        <img alt="Dell Technologies logo" height={25} src={DellTechnologiesLogo} width={195} />
      </BootstrapNavbar.Brand>
      <BootstrapNavbar.Toggle aria-controls="navbar-menu" />
      <BootstrapNavbar.Collapse id="navbar-menu">
        <Nav className="ms-auto">
          <Nav.Link
            className="Navbar__link text-dark"
            href="mailto:questions@dellreturncenter.com?subject=%20Question%20from%20Dell%20ReturnCenter"
          >
            Contact us
          </Nav.Link>
        </Nav>
      </BootstrapNavbar.Collapse>
    </Container>
  </BootstrapNavbar>
)
