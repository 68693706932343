export type Props = {
  address: {
    address1: string
    address2?: string
    city: string
    company: string
    email: string
    firstName: string
    lastName: string
    postalCode: string
    state: string
  }
}

export const AddressOverview = ({
  address: { firstName, lastName, company, address1, address2, city, state, postalCode, email },
}: Props) => (
  <div className="my-3 mx-4 fw-bold">
    <div>
      {firstName} {lastName}
    </div>
    <div>{company}</div>
    <div>{address1}</div>
    {address2 && <div>{address2}</div>}
    <div>
      {city} {state} {postalCode}
    </div>
    <br />
    <div>{email}</div>
  </div>
)
