import React from 'react'
import BootstrapButton from 'react-bootstrap/Button'
import { ButtonVariant } from 'react-bootstrap/types'
import classNames from 'classnames'
import Spinner from 'react-bootstrap/Spinner'

type Props = {
  children: React.ReactNode
  className?: string
  disabled?: boolean
  icon?: string
  iconAlt?: string
  loading?: boolean
  onClick?: () => void
  type?: 'button' | 'submit'
  variant?: ButtonVariant
}

export const Button = ({
  children,
  className,
  disabled,
  icon,
  iconAlt,
  loading,
  onClick,
  type = 'button',
  variant,
}: Props) => (
  <BootstrapButton
    aria-label={typeof children === 'string' ? children : ''}
    as={icon ? 'div' : 'button'}
    className={classNames('py-2', className)}
    disabled={disabled ?? loading}
    onClick={onClick}
    role="button"
    type={icon ? undefined : type}
    variant={variant}
  >
    {loading ? (
      <Spinner animation="border" size="sm" />
    ) : icon ? (
      <div className="d-flex align-items-center justify-content-center">
        <img alt={iconAlt} className="me-2" src={icon} />
        {children}
      </div>
    ) : (
      children
    )}
  </BootstrapButton>
)
