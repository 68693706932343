import { Address } from 'types'
import { endpoints } from 'api/endpoints'
import { http } from 'api/http'

export const address = {
  verify: (addressData: Address) => {
    return http.post(endpoints.address, {
      action: 'verify',
      address: {
        address1: addressData.address1,
        address2: addressData.address2,
        city: addressData.city,
        country: addressData.country,
        postal_code: addressData.postalCode,
        state_province: addressData.state,
      },
    })
  },
}
