import * as React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import { Layout } from 'components/Layout'
import { Spinner } from 'components/Spinner'

import { FormLayout } from 'components/FormLayout'
import { OrderProvider } from 'contexts/orderContext'
import { routes } from './routes'
import { SchedulePickupPage } from './pages/SchedulePickupPage'

const ConfirmAddressPage = React.lazy(() => import('pages/ConfirmAddressPage'))
const ConfirmAddressExternalPage = React.lazy(() => import('pages/ConfirmAddressExternal'))
const ConfirmPrintLabelPage = React.lazy(() => import('pages/ConfirmPrintLabelPage'))
const ConfirmSchedulePickupPage = React.lazy(() => import('pages/ConfirmSchedulePickupPage'))
const ErrorPage = React.lazy(() => import('pages/ErrorPage'))
const GenerateLabelPage = React.lazy(() => import('pages/GenerateLabelPage'))
const HomePage = React.lazy(() => import('pages/HomePage'))
const NotFoundPage = React.lazy(() => import('pages/NotFoundPage'))
const PickupsPage = React.lazy(() => import('pages/PickupsPage'))
const PrintLabelsPage = React.lazy(() => import('pages/PrintLabelsPage'))
const ReprintLabelPage = React.lazy(() => import('pages/ReprintLabelPage'))
const SelectTrackingNumbersPage = React.lazy(() => import('pages/SelectTrackingNumbersPage'))

export const App = () => {
  return (
    <OrderProvider>
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <React.Suspense fallback={<Spinner />}>
                <ErrorBoundary FallbackComponent={ErrorPage}>
                  <Layout />
                </ErrorBoundary>
              </React.Suspense>
            }
            path={routes.index}
          >
            <Route element={<HomePage />} index />

            <Route element={<FormLayout />}>
              <Route element={<ConfirmAddressPage />} path={routes.confirmAddress} />
              <Route
                element={<ConfirmAddressExternalPage />}
                path={routes.confirmAddressExternal}
              />
              <Route element={<ConfirmPrintLabelPage />} path={routes.confirmPrintLabel} />
              <Route element={<ConfirmSchedulePickupPage />} path={routes.confirmSchedulePickup} />
              <Route element={<GenerateLabelPage />} path={routes.generateLabel} />
              <Route element={<PickupsPage />} path={routes.pickups} />
              <Route element={<PrintLabelsPage />} path={routes.printLabels} />
              <Route element={<ReprintLabelPage />} path={routes.reprintLabel} />
              <Route element={<SchedulePickupPage />} path={routes.schedulePickup} />
              <Route element={<SelectTrackingNumbersPage />} path={routes.selectTrackingNumbers} />
            </Route>

            <Route element={<NotFoundPage />} path="*" />
          </Route>
        </Routes>
      </BrowserRouter>
    </OrderProvider>
  )
}
