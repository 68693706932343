import { Field, FieldProps } from 'formik'
import Form from 'react-bootstrap/Form'

export interface Props {
  className?: string
  label?: string
  name: string
  value: string | number | readonly string[] | undefined
}

export const RadioButton = ({ className, label, name, value }: Props) => (
  <Field name={name}>
    {({ field, meta }: FieldProps) => (
      <Form.Group className={className} controlId={`${name}_${value}`}>
        <Form.Check
          {...field}
          checked={field.value === value}
          isInvalid={Boolean(meta.error)}
          label={label}
          type="radio"
          value={value}
        />
      </Form.Group>
    )}
  </Field>
)
