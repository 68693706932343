export const earliestPickupTimeOptions = [
  {
    label: '5:00 am',
    value: '0500',
  },
  {
    label: '5:30 am',
    value: '0530',
  },
  {
    label: '6:00 am',
    value: '0600',
  },
  {
    label: '6:30 am',
    value: '0630',
  },
  {
    label: '7:00 am',
    value: '0700',
  },
  {
    label: '7:30 am',
    value: '0730',
  },
  {
    label: '8:00 am',
    value: '0800',
  },
  {
    label: '8:30 am',
    value: '0830',
  },
  {
    label: '9:00 am',
    value: '0900',
  },
  {
    label: '9:30 am',
    value: '0930',
  },
  {
    label: '10:00 am',
    value: '1000',
  },
  {
    label: '10:30 am',
    value: '1030',
  },
  {
    label: '11:00 am',
    value: '1100',
  },
  {
    label: '11:30 am',
    value: '1130',
  },
  {
    label: '12:00 pm',
    value: '1200',
  },
  {
    label: '12:30 pm',
    value: '1230',
  },
  {
    label: '1:00 pm',
    value: '1300',
  },
  {
    label: '1:30 pm',
    value: '1330',
  },
  {
    label: '2:00 pm',
    value: '1400',
  },
  {
    label: '2:30 pm',
    value: '1430',
  },
  {
    label: '3:00 pm',
    value: '1500',
  },
  {
    label: '3:30 pm',
    value: '1530',
  },
  {
    label: '4:00 pm',
    value: '1600',
  },
  {
    label: '4:30 pm',
    value: '1630',
  },
  {
    label: '5:00 pm',
    value: '1700',
  },
  {
    label: '5:30 pm',
    value: '1730',
  },
  {
    label: '6:00 pm',
    value: '1800',
  },
  {
    label: '6:30 pm',
    value: '1830',
  },
  {
    label: '7:00 pm',
    value: '1900',
  },
  {
    label: '7:30 pm',
    value: '1930',
  },
]

export const latestPickupTimeOptions = [
  ...earliestPickupTimeOptions.slice(1),
  {
    label: '8:00 pm',
    value: '2000',
  },
]
