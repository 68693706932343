import axios from 'axios'

const apiKey = import.meta.env.VITE_API_KEY ?? ''
const baseURL = import.meta.env.VITE_USE_MOCK_API === '1' ? '' : import.meta.env.VITE_API_URL ?? ''

export const http = axios.create({
  auth: {
    password: '',
    username: apiKey,
  },
  baseURL,
})
