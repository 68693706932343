import * as React from 'react'
import BootstrapCard from 'react-bootstrap/Card'

type Props = {
  children: React.ReactNode
}

export const Card = ({ children }: Props) => (
  <BootstrapCard className="bg-gray--light">
    <BootstrapCard.Body>{children}</BootstrapCard.Body>
  </BootstrapCard>
)
